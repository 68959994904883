import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import LoaderComponent from '../../components/LoaderComponent';
import './auth.less';

const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;
const redirectUri = `${process.env.REACT_APP_BE_HOST}${process.env.REACT_APP_GOOGLE_CALLBACK_ENDPOINT}`;

const Login = () => {
  const [searchParams] = useSearchParams();
  const writeKey = searchParams.get('writeKey');

  const handleOpenAuthUrl = () => {
    const googleAuthUrl = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${clientId}&redirect_uri=${redirectUri}&response_type=code&scope=openid%20profile%20email&prompt=select_account`;
    // eslint-disable-next-line no-undef
    window.location.href = googleAuthUrl;
  };

  useEffect(() => {
    if (writeKey) {
      // eslint-disable-next-line no-undef
      localStorage.setItem('writeKey', writeKey);
      handleOpenAuthUrl();
    } else {
      // eslint-disable-next-line no-undef
      window.location.href = process.env.REACT_APP_SPELL_CHECKER_WEB;
    }
  }, [writeKey]);

  return (
    <div className="loading-wrapper">
      <LoaderComponent size="large" />
    </div>
  );
};

export default Login;
