import { Card, Typography } from 'antd';
import React, { useEffect } from 'react';

const { Title, Text } = Typography;

const LoginFail = () => {
  useEffect(() => {
    setTimeout(() => {
      // eslint-disable-next-line no-undef
      window.location.href = process.env.REACT_APP_SPELL_CHECKER_WEB;
    }, 10000);
  }, []);

  return (
    <div className="login-fail-container">
      <Card className="login-fail-card" bordered={false}>
        <Title level={2} className="login-fail-title">
          Login Failed
        </Title>
        <Text className="login-fail-text">
          Oops! Something went wrong with your login. Please try again.
        </Text>
      </Card>
    </div>
  );
};

export default LoginFail;
