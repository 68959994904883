import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import api from '../../common/api';
import { ROUTES } from '../../common/constants';
import LoaderComponent from '../../components/LoaderComponent';
import useRouter from '../../hooks/useRouter';

const LoginSuccess = () => {
  const { navigate } = useRouter();
  const [searchParams] = useSearchParams();
  const googleIdToken = searchParams.get('token');

  useEffect(() => {
    const handleLogin = async () => {
      try {
        // eslint-disable-next-line no-undef
        const writeKey = localStorage.getItem('writeKey');
        if (googleIdToken && writeKey) {
          const body = {
            token: `Bearer ${googleIdToken}`,
            writeKey,
          };
          const res = await api.post('/', body);
          if (res?.data) {
            // eslint-disable-next-line no-undef
            window.location.href = process.env.REACT_APP_SPELL_CHECKER_WEB;
          }
        }
      } catch {
        navigate(ROUTES.LOGIN_FAIL);
      }
    };
    handleLogin();
  }, [googleIdToken]);

  return (
    <div className="loading-wrapper">
      <LoaderComponent size="large" />
    </div>
  );
};

export default LoginSuccess;
