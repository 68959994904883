import React from 'react';
import { BrowserRouter as Router, useRoutes } from 'react-router-dom';
import Error404 from './Error404';
import { ROUTES } from './common/constants';
import MaintenancePage from './components/MaintenancePage';
import Login from './modules/auth/Login';
import LoginFail from './modules/auth/LoginFail';
import LoginSuccess from './modules/auth/LoginSuccess';

const RoutesCollection = () => {
  const AUTH_MODULES = [
    {
      path: ROUTES.MAIN,
      element: <Login />,
    },
    {
      path: ROUTES.LOGIN_SUCCESS,
      element: <LoginSuccess />,
    },
    {
      path: ROUTES.LOGIN_FAIL,
      element: <LoginFail />,
    },
    {
      path: '*',
      element: <Error404 />,
    },
  ];

  const element = useRoutes([...AUTH_MODULES]);
  return element;
};

const RoutesWrapper = () => {
  // use this variable from envs so that we can able to run maintenance page on runtime.
  const maintenance = process.env.REACT_APP_MAINTENANCE_ENABLE;

  return (
    <Router>
      {maintenance === 'true' ? <MaintenancePage /> : <RoutesCollection />}
    </Router>
  );
};
export default RoutesWrapper;
